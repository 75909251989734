.main[data-v-f1c2fe50] {
  width: 390px;
  padding: 12px 16px;
}
.selectPeriod[data-v-f1c2fe50] {
  background-color: #409eff;
  color: #fff;
}
.periodLabel[data-v-f1c2fe50] {
  display: inline-block;
  width: 77px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 15px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}
