
.spark-line[data-v-f4142be1] {
	text-align: right;
	position: relative;
}
.spark-line_outer[data-v-f4142be1] {
	width: var(--f4142be1-width);
	height: 26px;
	background: var(--f4142be1-background);
}
